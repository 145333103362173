<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Settlement ${payment.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
            <div>
              <label class="text-gray-700" for="title"><b>User : </b></label>
              <router-link :to="{ path: `/users/${payment.user_id}` }">{{
                payment.user?.first_name || ''
              }} {{
                payment.user?.last_name || ''
              }}</router-link>
              <br>
              <small>{{ payment.user && payment.user.email }}</small>
            </div>

             <div>
              <label class="text-gray-700" for="title"><b>Phone Verified : </b></label>
              <span> {{
                payment.user?.phone_verified ? 'Yes' : 'No'
              }}</span>
           
            </div>

             <div>
              <label class="text-gray-700" for="title"><b>Email Verified : </b></label>
              <span> {{
                payment.user?.email_verified ? 'Yes' : 'No'
              }}</span>
           
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Valid Bank : </b></label>
              <span> {{
                payment.bankAccount ? 'Yes' : 'No'
              }}</span>
           
            </div>

        

            <div>
              <label class="text-gray-700" for="title"><b>Payments : </b></label>
              <router-link :to="{ path: `/payments`, query: { settlement_id: payment.id } }">Payments</router-link>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Description : </b></label
              >
          
              <textarea
              class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
              v-model="form.description"></textarea>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Note : </b></label
              >
              <wysiwyg
              class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
              v-model="form.note"></wysiwyg>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Bank Account : </b></label
              >
          
              <div
              class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
              >{{ JSON.stringify(payment.bankAccount, null, 4) }}</div>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Status : </b></label
              >
              <select v-model="form.status" class="form-input w-full mt-2 rounded-md focus:border-indigo-600">
                <option value="pending">Pending</option>
                <option value="successful">Successful</option>
                <option value="processing">Processing</option>
                <option value="ready">Ready</option>
                <option value="failed">Failed</option>
              </select>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Source : </b></label
              >
              <select v-model="form.source" class="form-input w-full mt-2 rounded-md focus:border-indigo-600">
                <option value="paystack">Paystack</option>
                <option value="flutterwave">Flutterwave</option>
                <option value="stripe">Stripe</option>
              </select>
            </div>


            <div>
              <label class="text-gray-700" for="title"
                ><b
                  >Amount ({{payment.currency?.symbol}}):
                </b></label
              >
              <input
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="form.amount"
                />
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b
                  >EXT REF:
                </b></label
              >
              <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="form.ext_ref"
                />
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Date : </b></label>
              <span class="">{{
                toLocal(payment.created_at).format("lll")
              }}</span>
            </div>


          </div>

          <div
            class="flex justify-end mt-4"
            
          >
            <button

              :disabled="loading"
              @click="update"
              :class="`px-8 py-2 mr-4 bg-gray-${
                loading ? 200 : 800
              } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                loading ? 100 : 700
              }`"
            >
              {{ loading ? "Updating..." : "Update Settlement" }}
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { optional, getMargin } from "@/helpers/global";
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title:() => {
     return `Settlement #${window.location.href.split('/').pop()} | Settlements`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      form: {
        amount: 0,
      },
    };
  },
  methods: {
    toLocal,
    optional,
    getMargin,
    update() {
      let r = confirm(
        `Are you sure you want to update settlement #${this.payment.id}?`
      );
      if (!r) return;
      this.loading = true;
      this.$store
        .dispatch("settlements/update", this.form)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update settlement");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    payment() {
      return this.$store.getters["settlements/settlement"];
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("settlements/getSingle", {
        id: this.$route.params.id,
        include: "user,subscription",
      }),
    ]).finally(() => {
      this.loaded = true;
      Object.assign(this.form, this.payment);
    });
  },
};
</script>
