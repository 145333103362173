<template>
    <div :class="{ blur: !loaded }">
      <h3 class="text-gray-700 text-3xl font-semibold">
        Create Service
      </h3>
  
      <div class="mt-8">
        <div class="mt-4">
          <div class="p-6 bg-white rounded-md shadow-md">
            <form @submit.prevent="update">
              <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
                <div>
                  <label class="text-gray-700" for="title"><b>Name : </b></label>
                  <input
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.name"
                  />
                </div>
              
                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Category : </b></label
                  >
                  <input
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.category"
                  />
                </div>
  
                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Color : </b></label
                  >
                  <input
                    type="color"
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.color"
                  />
                </div>
  
               
                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Is Active : </b></label
                  >
                  <input
                    type="checkbox"
                    v-model="service.active"
                  />
                </div>

                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Is Visible : </b></label
                  >
                  <input
                    type="checkbox"
                    v-model="service.visible"
                  />
                </div>

               
                 <div>
                  <label class="text-gray-700" for="title"
                    ><b>Sub Plans : </b></label
                  >
                  <textarea
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.subplans"
                  ></textarea>
                </div>

        
              <div>
                  <label class="text-gray-700" for="title"
                    ><b>Description : </b></label
                  >
                  <textarea
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.description"
                  ></textarea>
                </div>

               

  
                <div class="flex justify-end mt-4">
                  <button
                    :disabled="loading"
                    @click="save"
                    :class="`px-4 py-2 bg-gray-${
                      loading ? 200 : 800
                    } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                      loading ? 100 : 700
                    }`"
                  >
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script >
  import { handleApiError } from "@/helpers/error";
  import { toLocal } from "@/helpers/date";
  
  export default {
    title: "Create Service | Services",
    data() {
      return {
        loaded: false,
        loading: false,
        service: {
          require_email: false,
          active: false,

          visible: false,
          auto: false,
          meta: {
            
          }
        }
      };
    },
    methods: {
      toLocal,
      save() {
        this.$validator.validateAll().then((valid) => {
          if (valid) {
            this.loading = true;
            this.submit();
          }
        });
      },
      submit() {
        let data = {};
  
        Object.assign(data, this.service);
        data.subplans = data.subplans ? data.subplans.split('|') : []
  
        this.$store
          .dispatch("services/create", data)
          .then(({ message }) => {
            this.$toast.success(message, {
              position: "top-right",
              duration: 3000,
            });
          })
          .catch((err) => {
            handleApiError(this, err, "Failed to create service");
          })
          .finally(() => (this.loading = false));
      },
    },
    computed: {
      
    },
    created() {
     this.loaded = true
    },
  };
  </script>
  