<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Bank Account ${account.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="() => {}">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">

              <div>
                <label class="text-gray-700" for="title"
                  ><b>User : </b></label
                >
                <router-link v-if="account.user" :to="{ path: `/users/${account.user.id}` }">{{
                  account.user.username}}
                
                  <span style="color: brown"><i>({{ account.user.first_name }} {{ account.user.last_name }})</i></span>
                </router-link>
              </div>

              <div v-for="(v, k) in account" :key="k" v-if="typeof v === 'string' && !exempted.includes(k)">
             
                <label class="text-gray-700" for="title"
                  ><b>{{ toTitleCase(k.replaceAll('_', ' ')) }} : </b></label
                >
                <input v-model="form[k]" class="form-input w-full mt-2 rounded-md focus:border-indigo-600">
              
              </div>

              <div>
                  <label class="text-gray-700" for="title"
                    ><b>Approved : </b></label
                  >
                  <input
                    type="checkbox"
                    v-model="form.approved"
                  />
                </div>
                <div >
             
             <label class="text-gray-700" for="title"
               ><b>Reason: </b></label
             >
             <input v-model="form['reason']" class="form-input w-full mt-2 rounded-md focus:border-indigo-600">
           
           </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(account.updated_at).format("lll")
                }}</span>
              </div>

              <div
            class="flex justify-end mt-4"
            
          >
            <button v-if="hasRole($auth.user(), 'admin')"
              :disabled="loading"
              @click="update"
              :class="`px-8 py-2 mr-4 bg-gray-${
                loading ? 200 : 800
              } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                loading ? 100 : 700
              }`"
            >
              {{ loading ? "Updating..." : "Update Account" }}
            </button>

          </div>

              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { toLocal } from "@/helpers/date";
import { hasRole, toTitleCase } from "@/helpers/global";

export default {
  title:() => {
     return `Bank Account ${window.location.href.split('/').pop()} | Bank Accounts`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      form: {},
      exempted: ['user_id', 'reference', 'country_id', 'currency_id', 'created_at', 'updated_at', 'approved'],
    };
  },
  methods: {
    toLocal,
    hasRole,
    toTitleCase,
    update() {
      let r = confirm(
        `Are you sure you want to update this bank account?`
      );
      if (!r) return;
      this.loading = true;
      this.$store
        .dispatch("bankAccounts/update", this.form)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update account");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    account() {
      return this.$store.getters["bankAccounts/bank_account"];
    },
  },
  created() {
    this.$store
      .dispatch("bankAccounts/getSingle", {
        id: this.$route.params.id,
      })
      .finally(() => {
        this.loaded = true;
        Object.assign(this.form, this.account)
        for(let k of this.exempted) {
          delete this.form[k]
        }
        for(let k of Object.keys(this.form)) {
          if(this.form[k] !== null && typeof this.form[k] === 'object') {
            delete this.form[k]
          }
        }
        this.form.approved = !!this.account.approved
      });
  },
};
</script>
