<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total settlement transactions</small
            >
          </div>
        </div>
        <br />
        <filter-dropdown
          @setFilters="setFilters"
          :params="moreParams"
        ></filter-dropdown>
        <button
          :class="`px-2 py-3 bg-red-600 rounded-md text-white font-medium tracking-wide hover:bg-red-200`"
          @click="clearFilters()"
        >
          Clear Filters
        </button>
        
      </div>
      <div
        class="align-middle inline-block shadow sm:rounded-lg border-b border-gray-200"
      >
        <vuetable
          ref="vuetable"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: { Authorization: 'Bearer ' + $auth.token() },
          }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="goto"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            {{ props.rowIndex + 1 }}
          </template>

          <template slot="user" slot-scope="props">
            <router-link
              :to="{ path: `/users/${props.rowData.user_id}` }"
            >
              <span class="text-red-500">
                {{
                  `${props.rowData.user.first_name || ''} ${props.rowData.user.last_name || ''}`
                }}
                <br /><small class="text-blue-500">{{
                  props.rowData.user.email
                }}</small></span
              ></router-link
            >
          </template>


          <template slot="reference" slot-scope="props">
           
              {{ props.rowData.reference }}
           
          </template>

          <template slot="amount" slot-scope="props">
            {{
              props.rowData.currency && props.rowData.currency.symbol
            }}{{
              parseFloat(
                props.rowData.amount
              ).toFixed(2) | numberWithCommas
            }}
          </template>
        

          <template slot="actions" slot-scope="props">
            <div class="dropdown block relative">
              <button
                class="
                  bg-gray-300
                  text-gray-700
                  font-semibold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                "
              >
                <span class="mr-1">Actions</span>
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu hidden text-gray-700 pt-1">
                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{ path: `/settlements/${props.rowData.id}` }"
                    >View</router-link
                  >
                </li>

                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{
                      path: `/payments`,
                      query: { settlement_id: props.rowData.id },
                    }"
                    >Payments</router-link
                  >
                </li>
              </ul>
            </div>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import FilterDropdown from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import { toLocal } from "@/helpers/date";
import { optional, getMargin, hasRole } from "@/helpers/global";
import moment from "moment";

export default {
  title: "Settlements",
  components: { FilterDropdown, VuetablePagination },
  data() {
    return {
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "id",
          title: "ID",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:reference",
          title: "Reference",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:user",
          title: "User",
          sortField: "user_id",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:amount",
          title: "Amount",
          sortField: "amount",
          cvisible: true,
          active: true,
        },
        {
          name: "description",
          title: "Description",
          cvisible: true,
          active: true,
        },
        {
          name: "status",
          title: "Status",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color = s === "successful" ? "green" : "red";
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${s}</span>`;
          },
        },
        {
          name: "source",
          title: "Source",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color = s === "stripe" ? "green" : "red";
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${s}</span>`;
          },
        },
        {
          name: "created_at",
          title: "Created At",
          cvisible: true,
          active: true,
          sortField: "created_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },

        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        page: 1,
        service_id: [],
      },
      paginationData: {},
      api: `${this.$baseApi}/admin/settlements?include=currency,user`,
      margin: 0,
      totalAmount: 0,
      marginPerMonth: 0,
      currency: null,
      daysAgo: null,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    optional,
    getMargin,
    hasRole,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    goto(e) {
      this.$router.push({ path: `/settlements/${e.id}` });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        let data;
        switch (key) {

          case "created_at_from":
          case "created_at_to":
            if (value && value.split("T").length > 1)
              this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {
        per_page: 100,
        page: 1,
      };
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
  },
  computed: {
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    rates() {
      return this.$store.getters["global/rates"];
    },
    launchDate() {
      return this.$store.getters["global/launchDate"];
    },
  },
  created() {
    let moreParams = this.moreParams;
    this.moreParams = { ...moreParams, ...this.$route.query };
    let given = moment(this.launchDate);
    var current = moment().startOf("day");

    //Difference in number of days
    this.daysAgo = Math.abs(moment.duration(given.diff(current)).asDays());

    Promise.all([
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
    ]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>
<style scoped>
.stat {
  padding: 2px;
}
</style>