<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Dispute #${ticket.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
            <div>
              <label class="text-gray-700" for="title"><b>User : </b></label>
              <router-link v-if="ticket.user" :to="{ path: `/users/${ticket.user?.id}` }">{{
    ticket.user.first_name || ''
  }}</router-link><span v-else>N/A</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Seller : </b></label>
              <router-link v-if="ticket.seller" :to="{ path: `/users/${ticket.seller?.id}` }">{{
    ticket.seller.first_name || ''
  }}</router-link><span v-else>N/A</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Subscription : </b></label>
              <router-link v-if="ticket.subscription" :to="{ path: `/subscriptions/${ticket.subscription?.id}` }">{{
    ticket.subscription.reference
  }}</router-link>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Offer : </b></label>
              <router-link v-if="ticket.subscription?.offer"
                :to="{ path: `/offers/${ticket.subscription.offer?.id}` }">{{
    ticket.subscription.offer.title
  }}</router-link>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Service : </b></label>
              {{
      ticket.subscription?.service?.name
    }}
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Subject : </b></label>
              <span class="">{{ ticket.subject }}</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Initial Message : </b></label>
              <i><span class="" v-html="ticket.message"></span></i>
            </div>
            <div>
              <h6>Conversation History</h6>
              <ul>
                <li style="border: solid 1px black; padding: 5px; margin: 5px;" v-for="message in messages"
                  :key="message.reference">
                  <span
                  :style="`color: ${message.admin ? 'green' : message.user?.id === ticket.user?.id ? 'blue' : 'red'};`">
                  {{ message.admin ? `${message.admin.username || message.admin.first_name}
                    (Admin)` : message.user?.id === ticket.user?.id ? `${message.user?.username} (Buyer)` :
    `${ticket.user?.username} (Seller)` }}</span>
                  <br>
                  <span v-html="message.message"></span>
                  <br>
                  <small><i>{{ toLocal(message.created_at).format('lll') }}</i></small>
                </li>
              </ul>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Add Message : </b></label>
              <wysiwyg class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  " v-model="body.message" />
            </div>

            <div v-if="ticket.seller?.seeded">
              <label class="text-gray-700" for="title"><b>Reply As Seller : </b></label>
              <input type="checkbox" v-model="body.as_seller" />
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Status : </b></label>
              <select name="status" class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                v-model="body.status">
                <option :value="k" v-for="(k, v) in statuses">{{ v }}</option>
              </select>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Last Updated : </b></label>
              <span class="">{{
    toLocal(ticket.updated_at).format("lll")
  }}</span>
            </div>

            <div class="flex justify-end mt-4">
              <button :disabled="loading" @click="sendMessage" :class="`px-4 py-2 bg-gray-${loading ? 200 : 800
    } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${loading ? 100 : 700
    }`">
                {{ loading ? 'Sending...' : `Send Message${body.as_seller? ' As Seller' : ''}` }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { optional } from "@/helpers/global";
import { toLocal, toUTC } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title: () => {
    return `Dispute #${window.location.href.split('/').pop()} | Disputes`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      body: {
        message: '',
      },
      statuses: {
        PENDING: 'pending',
        HANDLING: 'handling',
        AWAITING_BUYER: 'awaiting_buyer',
        AWAITING_SELLER: 'awaiting_seller',
        RESOLVED: 'resolved',
      }
    };
  },
  methods: {
    toLocal,
    optional,
    sendMessage() {
      let r = confirm(`Are you sure you want to reply this ticket as ${this.body.as_seller ? 'seller' : 'admin'}?`)

      if (!r) {
        return false
      } else if (this.body.as_seller) {
        return this.sendMessageAsSeller()
      } else {
        return this.sendMessageAsAdmin()
      }
    },
    sendMessageAsAdmin() {
      this.loading = true
      this.$store
        .dispatch("tickets/sendMessage", this.body)
        .then(({ data, message }) => {
          data.admin = this.$auth.user()
          const messages = this.messages
          messages.push(data)
          this.$store.commit('tickets/SET_MESSAGES', messages)
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.body.message = ''
          this.body.as_seller = false
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to add message");
        })
        .finally(() => (this.loading = false));
    },
    sendMessageAsSeller() {
      this.loading = true
      this.$store
        .dispatch("tickets/sendMessageAsSeller", this.body)
        .then(({ data, message }) => {
          data.user = this.ticket.seller
          const messages = this.messages
          messages.push(data)
          this.$store.commit('tickets/SET_MESSAGES', messages)
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.body.message = ''
          this.body.as_seller = false
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to add message");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ticket() {
      return this.$store.getters["tickets/ticket"];
    },
    messages() {
      let m = this.$store.getters["tickets/messages"];
      m.sort((a, b) => toUTC(a.created_at) - toUTC(b.created_at))
      return m
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("tickets/getSingle", {
        id: this.$route.params.id,
      }),
      this.$store.dispatch("tickets/getMessages", {
        id: this.$route.params.id,
        sort: 'created_at|desc'
      }),
    ]).finally(() => {
      this.loaded = true;
      this.body.id = this.ticket.id
      this.body.status = this.ticket.status
    });
  },
};
</script>
