<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Offer #${offer.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="() => {}">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Title : </b></label
                >
                <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="offer.title"
                />
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Service : </b></label
                >
                <router-link v-if="offer.service" :to="{ path: `/services/${offer.service.id}` }">{{
                  offer.service && offer.service.name
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>User : </b></label
                >
                <router-link v-if="offer.user" :to="{ path: `/users/${offer.user.id}` }">{{
                  offer.user && offer.user.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Subscriptions : </b></label
                >
                <router-link  :to="{ path: `/subscriptions`, query : { offer_id: offer.id} }">{{
                  'Subscriptions'
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reference : </b></label
                >
                <a target="_blank" v-if="offer.service" :href="`https://mysub.io/services/${offer.service.slug}/${offer.slug}`">{{
                  offer.reference
                }}</a>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Status : </b></label>
                <select
                  name="status"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="offer.status"
                >
                <option value="active">Active</option>
                <option value="rejected">Rejected</option>
                <option value="pending">Pending</option>
                <option value="disabled">Disabled</option>
                <option value="hidden">Hidden</option>
                </select>
              </div>
             
              <div v-if="offer.subplan">
              <label class="text-gray-700" for="title"
                  ><b>Subplan : </b></label
                >
                <span  >{{
                  offer.subplan
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>In Use : </b></label
                >
                <span>{{
                  offer.subscriptions_count
                    ? offer.subscriptions_count
                    : "No"
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Status : </b></label
                >
                <span  >{{  offer.status }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Duration : </b></label
                >
                <span  >{{  offer.duration || 'N/A' }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Description : </b></label>
                <wysiwyg
                  
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="offer.description"
                />
              </div>

          
              <div>
                <label class="text-gray-700" for="title"><b>Slots : </b></label>
                <input
                  type="number"
                  min="1"
                  max="100"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="offer.slots"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Amount({{ offer.currency?.symbol }}): </b></label>
                <input
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="offer.amount"
                />
              </div>

              <div v-if="offer.seeded">
                <label class="text-gray-700" for="title"><b>Details : </b></label>
                <wysiwyg
                  
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="offer.details"
                />
              </div>

              <div v-else>
                <label class="text-gray-700" for="title"><b>Details : </b></label>
                <div v-html="offer.details"
                  
                ></div>
              </div>

              <div v-if="!offer.seeded">
                <label class="text-gray-700" for="title"><b>Reason & Suggested Fix : </b></label>
                <textarea
                  
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="offer.reason"
                ></textarea>
              </div>

               <div v-if="offer.note">
                <label class="text-gray-700" for="title"><b>Note : </b></label>
                <div
                 v-html="offer.note"
                ></div>
              </div>

              <div>
                  <label class="text-gray-700" for="title"
                    ><b>Auto-assign : </b></label
                  >
                  <input
                    type="checkbox"
                    v-model="offer.auto_assign"
                  />
                </div>

         
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(offer.updated_at).format("lll")
                }}</span>
              </div>

              <div class="flex justify-end mt-4">
                <button v-if="offer.seeded"
                  :disabled="loading"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ offer.seeded ? 'Update' : 'Not Internal' }}
                </button>
                <button v-else
                  :disabled="loading"
                  @click="updateStatus"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ loading ? 'Updating Status' : 'Update Status' }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";

export default {
  title:() => {
     return `Offer #${window.location.href.split('/').pop()} | Offers`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      offer: {
        meta: {
          card: null,
          phone: null,
        }
      },
    };
  },
  methods: {
    toLocal,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let r = confirm(`${this.offer.subscriptions_count} subscribers will be notified. Are you sure you want to update this offer?`)
      if(!r) {
        this.loading = false;
        return
      }
      let data = {};

      Object.assign(data, this.offer);

      this.$store
        .dispatch("offers/update", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update offer");
        })
        .finally(() => (this.loading = false));
    },
    updateStatus() {
      this.loading = true
      let r = confirm(`Are you sure you want to update the status of this offer to ${this.offer.status}?`)
      if(!r) {
        this.loading = false;
        return
      }
   

      this.$store
        .dispatch("offers/updateStatus", {
          id: this.currentOffer.id,
          status: this.offer.status,
          reason: this.offer.reason,
          title: this.offer.title,
          description: this.offer.description,
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update offer");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    currentOffer() {
      return this.$store.getters["offers/offer"];
    },
  },
  created() {
    this.$store
      .dispatch("offers/getSingle", {
        id: this.$route.params.id,
        include: "user",
      })
      .finally(() => {
        Object.assign(this.offer, this.currentOffer)
        this.offer.meta = this.offer.meta || {}
        this.loaded = true;
      });
  },
};
</script>
