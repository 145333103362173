<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-medium">Dashboard</h3>
    <h5 v-if="report && report.range">
      {{ report.days }} days ({{
        toLocal(report.range.created_at_from).format("ll")
      }}
      to {{ toLocal(report.range.created_at_to).format("ll") }})
    </h5>
    <h6>
      <small><i>Generated at {{ toLocal().format("lll") }}</i></small>
    </h6>

    <div class="mt-4" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')">
      <div class="flex flex-wrap -mx-6">

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 4 : 3
          }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  "$"
                }}{{
  parseFloat(
    report.gross_volume
  ).toFixed(2) | numberWithCommas
}}
              </h4>
              <div class="text-gray-500">Gross Volume</div>
              <sub>({{ report.total_payments | numberWithCommas }} transactions)</sub>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 4 : 3
          }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                 "$"
                }}{{
  parseFloat(
    report.net_volume
  ).toFixed(2) | numberWithCommas
}}
              </h4>
              <div class="text-gray-500">Net Volume</div>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 4 : 3
            }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  "$"
                }}{{
  parseFloat(
   report.payment_fee_sum
  ).toFixed(2) | numberWithCommas
}}
              </h4>
              <div class="text-gray-500">Fees</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="mt-4" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')">
      <div class="flex flex-wrap -mx-6">


        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 4 : 2
          }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  report.total_subscriptions | numberWithCommas
                }}
              </h4>
              <div class="text-gray-500">Subscriptions</div>
              <sub>({{ report.active_subscriptions | numberWithCommas }} active)</sub>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 4 : 2
          }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{ report.total_users | numberWithCommas }}
              </h4>
              <div class="text-gray-500">Users</div>
              <sub>({{ report.active_users | numberWithCommas }} active)</sub>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="hasRole($auth.user(), 'admin')">
      <div class="flex flex-wrap -mx-6">
        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 5 : 2
          }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  "$"
                }}{{
  parseFloat(
   report.mrr
  ).toFixed(2) | numberWithCommas
}}
              </h4>
              <div class="text-gray-500">MRR</div>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 5 : 2
            }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  "$"
                }}{{
  parseFloat(
    report.mrr * 12
  ).toFixed(2) | numberWithCommas
}}
              </h4>
              <div class="text-gray-500">ARR</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="mt-4" v-if="$route.query.internal && (hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth'))">
      <div class="flex flex-wrap -mx-6">

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 4 : 2
          }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  "$"
                }}{{
  parseFloat(
    report.total_expense
  ).toFixed(2) | numberWithCommas
}}
              </h4>
              <div class="text-gray-500">Expenses </div>
            </div>
          </div>
        </div>


        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/${$route.query.internal ? 4 : 2
            }`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  "$"
                }}{{
  parseFloat(
    report.margin
  ).toFixed(2) | numberWithCommas
}}
              </h4>
              <div class="text-gray-500">Margin </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')">
      <div class="flex flex-wrap -mx-6">
        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/5`">
          <div class="items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="text-center">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{ parseInt(initial.rate) | numberWithCommas }}%
              </h4>
              <div class="text-gray-500">Target Growth Rate</div>
              <div>Per Month</div>
            </div>
          </div>
        </div>
        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/5`">
          <div class="items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="text-center">
              <h4 class="text-2xl font-semibold text-gray-700">

                <sup>{{ report.total_subscriptions }}/</sup>{{
                  parseInt(
                    report.prev_total_subscriptions + ((initial.rate / 100) * report.prev_total_subscriptions)
                  ) | numberWithCommas
                }}<sub class="text-xs text-red-400">{{
  parseFloat(
    (report.total_subscriptions / (report.prev_total_subscriptions + ((initial.rate / 100) *
      report.prev_total_subscriptions))) * 100
  ).toFixed(2)
}}%</sub>
              </h4>
              <div class="text-gray-500">Subscriptions Target</div>
              <div class="">
                <progress :title="`${parseFloat(
                      (report.total_subscriptions / (report.prev_total_subscriptions + ((initial.rate / 100) * report.prev_total_subscriptions))) * 100
                    ).toFixed(2)}% growth this month`"
                    :value="report.total_subscriptions"
                  :max="report.prev_total_subscriptions + ((initial.rate / 100) * report.prev_total_subscriptions)"></progress>
              </div>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/5`">
          <div class="items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="text-center">
              <h4 class="text-2xl font-semibold text-gray-700">
                <sup>{{ report.total_users }}/</sup>{{
                  parseInt(
                    report.prev_total_users + ((initial.rate / 100) * report.prev_total_users)
                  ) | numberWithCommas
                }}<sub class="text-xs text-red-400">{{
  parseFloat(
    (report.total_users / (report.prev_total_users + ((initial.rate / 100) *
      report.prev_total_users))) * 100
  ).toFixed(2)
}}%</sub>
              </h4>
              <div class="text-gray-500">Users Target</div>
              <progress :title="`${parseFloat(
                    (report.total_users / (report.prev_total_users + ((initial.rate / 100) * report.prev_total_users))) * 100
                  ).toFixed(2)}% growth this month`" :value="report.total_users"
                :max="report.prev_total_users + ((initial.rate / 100) * report.prev_total_users)"></progress>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/5`">
          <div class="items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="text-center">
              <h4 class="text-2xl font-semibold text-gray-700">
                <sup>
                  {{
                    "$"
                  }}{{
  parseFloat(
    report.mrr
  ).toFixed(2) | numberWithCommas
}}/</sup>{{
  "$"
}}{{
  parseFloat(
    report.last_mrr + ((initial.rate / 100) * report.last_mrr)
  ).toFixed(2) | numberWithCommas
}}<sub class="text-xs text-red-400">{{
  parseFloat(
    ((report?.mrr - report?.last_mrr) / report?.last_mrr) * 100
  ).toFixed(2)
}}%</sub>
              </h4>
              <div class="text-gray-500">
                MRR Target
                <div>
                  <progress :title="`${parseFloat(
                        ((report.mrr - report.last_mrr) / report.last_mrr) * 100
                      ).toFixed(2)}% growth this month`" :value="report.mrr"
                    :max="report.last_mrr + ((initial.rate / 100) * report.last_mrr)"></progress>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/5`">
          <div class="items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="text-center">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{ nextTarget.mrr && nextTarget.mrr.days }} days
              </h4>
              <div class="text-gray-500">
                Next Milestone
                <sub class="text-xs text-green-400">({{
                  nextTarget.total_subscriptions &&
                  nextTarget.total_subscriptions.date
                }})</sub>
              </div>
              <progress :value="30 - (nextTarget.mrr ? nextTarget.mrr.days : 0)" max="30"></progress>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')">
      <div class="flex flex-wrap -mx-6">


        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/3`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{
                  parseFloat(report.user_avg_growth_rate).toFixed(2) | numberWithCommas
                }}%
              </h4>
              <div class="text-gray-500">Average User Growth</div>
              <sub>({{ report.interval }} months)</sub>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/3`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{ parseFloat(report.sub_avg_growth_rate).toFixed(2) | numberWithCommas }}%
              </h4>
              <div class="text-gray-500">Average Subscription Growth</div>
              <sub>({{ report.interval }} months)</sub>
            </div>
          </div>
        </div>

        <div :class="`w-full py-1 px-6 sm:w-1/2 xl:w-1/3`">
          <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                  fill="currentColor" />
                <path
                  d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                  fill="currentColor" />
                <path
                  d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                  fill="currentColor" />
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">
                {{ parseFloat(report.payment_avg_growth_rate).toFixed(2) | numberWithCommas }}%
              </h4>
              <div class="text-gray-500">Average Transaction Growth</div>
              <sub>({{ report.interval }} months)</sub>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8"></div>

    <div class="flex flex-col mt-8" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')">
      <h3 class="text-gray-700 text-3xl font-medium">Confirm Payment</h3>
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <div>
            <label class="text-gray-700" for="title"><b>Reference : </b></label>
            <input class="form-input w-full mt-2 rounded-md focus:border-indigo-600" v-validate="'min:16|max:16'"
              v-model="reference" />
          </div>
          <div class="flex justify-end mt-4">
            <button v-if="reference" :disabled="loading" @click="confirmPayment(reference)" :class="`px-4 py-2 bg-red-${loading ? 200 : 800
              } text-red-200 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-${loading ? 100 : 700
              }`">
              {{ loading ? "Confirming..." : "Confirm" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-8" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')">
      <h6 class="text-gray-700 text-3xl font-medium">
        {{ paymentsMeta.total }} Payments Today
      </h6>
      <div style="text-align: center">
        <small>{{ paymentsMeta.total }}/{{
          parseInt(
            ((nextTarget.total_payments
              ? nextTarget.total_payments.cumulative
              : 0) -
              report.total_payments) /
            (nextTarget.total_payments && nextTarget.total_payments.days)
          )
        }}</small>
      </div>
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Reference
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Subscription
                </th>

                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Service
                </th>

                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>

                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Duration
                </th>

                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Created
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  View
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="payment in payments" :key="payment.id">
                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    {{ payment.reference }}
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link v-if="payment.user" :to="{ path: `/users/${payment.user_id}` }"
                      :title="`${payment.user.first_name} ${payment.user.last_name}`">
                      <span class="text-red-500">
                        {{
                          `${payment.user.first_name || ''} ${payment.user.last_name || ''}`
                        }}
                        <br /><small class="text-blue-500">{{
                          payment.user.email
                        }}</small></span></router-link>
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link v-if="payment.subscription" :to="{
                      path: `/subscriptions/${payment.subscription_id}`,
                    }">{{
  payment.subscription && payment.subscription.reference
}}</router-link>
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link v-if="payment.subscription" :to="{
                      path: `/services/${payment.subscription.service_id}`,
                    }">{{
  payment.subscription &&
  payment.subscription.service &&
  payment.subscription.service.name
}}</router-link>
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    {{
                      payment &&
                      payment.currency &&
                      payment.currency.symbol
                    }}{{
  parseFloat(
    payment.amount
  ).toFixed(2) | numberWithCommas
}}
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900" v-if="payment.subscription">
                    {{ payment.subscription && payment.subscription.months }}
                  </div>
                </td>

                <td :class="{
                  'px-6': true,
                  'py-4': true,
                  'whitespace-no-wrap': true,
                  'border-b': true,
                  'border-gray-200': true,
                  'text-sm': true,
                  'leading-5': true,
                  'text-orange-500': true,
                }">
                  {{ toLocal(payment.created_at).format("lll") }}
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link :to="{ path: `/payments/${payment.id}` }">View</router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-8" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')">
      <h6 class="text-gray-700 text-3xl font-medium">
        {{ subscriptionsMeta.total }} Subscriptions Today
      </h6>
      <div style="text-align: center">
        <small>{{ subscriptionsMeta.total }}/{{
          parseInt(
            ((nextTarget.total_subscriptions
              ? nextTarget.total_subscriptions.cumulative
              : 0) -
              report.total_subscriptions) /
            (nextTarget.total_subscriptions &&
              nextTarget.total_subscriptions.days)
          )
        }}</small>
      </div>
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Reference
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Service
                </th>

                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Offer
                </th>

                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>

                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Created
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  View
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="subscription in subscriptions" :key="subscription.id">
                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    {{ subscription.reference }}
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link :to="{ path: `/users/${subscription.user_id}` }"
                      :title="`${subscription.user.first_name} ${subscription.user.last_name}`">
                      <span class="text-red-500">
                        {{
                          `${subscription.user.first_name || ''} ${subscription.user.last_name || ''}`
                        }}
                        <br /><small class="text-blue-500">{{
                          subscription.user.email
                        }}</small></span></router-link>
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link :to="{ path: `/services/${subscription.service_id}` }">{{
                      subscription.service && subscription.service.name
                    }}</router-link>
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link v-if="subscription.offer" :to="{ path: `/offers/${subscription.offer.id}` }">{{
                      subscription.offer && subscription.offer.reference
                    }}</router-link>
                    <span v-else>N/A</span>
                  </div>
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    {{ subscription.active ? "Active" : "Deactivated" }}
                  </div>
                </td>

                <td :class="{
                  'px-6': true,
                  'py-4': true,
                  'whitespace-no-wrap': true,
                  'border-b': true,
                  'border-gray-200': true,
                  'text-sm': true,
                  'leading-5': true,
                  'text-orange-500': true,
                }">
                  {{ toLocal(subscription.created_at).format("lll") }}
                </td>

                <td class="px-2 py-1 border-b border-gray-200">
                  <div class="text-sm leading-1 text-gray-900">
                    <router-link :to="{ path: `/subscriptions/${subscription.id}` }">View</router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-8" v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')">
      <h6 class="text-gray-700 text-3xl font-medium">
        {{ usersMeta.total }} Users Today
      </h6>
      <div style="text-align: center">
        <small>{{ usersMeta.total }}/{{
          parseInt(
            ((nextTarget.total_users ? nextTarget.total_users.cumulative : 0) -
              report.total_users) /
            (nextTarget.total_users && nextTarget.total_users.days)
          )
        }}</small>
      </div>
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table class="min-w-full">
            <thead>
              <tr>
                <th class="px-2 py-1 border-b bg-gray-10 text-left text-xs leading-4 font-medium text-gray-500 uppercase">
                  Name
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Subscriptions
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  LTV
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Source
                </th>
                <th
                  class="px-6 py-3 border-b bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Registered
                </th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="user in users" :key="user.id">
                <td class="px-2 py-1 border-b border-gray-200">
                  {{ user.first_name || '' }} {{ user.last_name || '' }}
                </td>

                <td :class="{
                  'px-6': true,
                  'py-4': true,
                  'whitespace-no-wrap': true,
                  'border-b': true,
                  'border-gray-200': true,
                  'text-sm': true,
                  'leading-5': true,
                  'text-orange-500': true,
                }">
                  {{ user.email }}
                </td>

                <td :class="{
                  'px-6': true,
                  'py-4': true,
                  'whitespace-no-wrap': true,
                  'border-b': true,
                  'border-gray-200': true,
                  'text-sm': true,
                  'leading-5': true,
                  'text-yellow-500': true,
                }">
                  {{ user.$extras.subscriptions_count }}
                </td>
                <td :class="{
                  'px-6': true,
                  'py-4': true,
                  'whitespace-no-wrap': true,
                  'border-b': true,
                  'border-gray-200': true,
                  'text-sm': true,
                  'leading-5': true,
                  'text-blue-500': true,
                }">
                  {{
                     "$"
                  }}{{
  parseFloat(
    user.$extras.ltv || 0
  ).toFixed(2) | numberWithCommas
}}
                </td>
                <td :class="{
                      'px-6': true,
                      'py-4': true,
                      'whitespace-no-wrap': true,
                      'border-b': true,
                      'border-gray-200': true,
                      'text-sm': true,
                      'leading-5': true,
                      'text-green-500': true,
                    }">
                  {{ user.source || "N/A" }}
                </td>
                <td :class="{
                  'px-6': true,
                  'py-4': true,
                  'whitespace-no-wrap': true,
                  'border-b': true,
                  'border-gray-200': true,
                  'text-sm': true,
                  'leading-5': true,
                  'text-orange-500': true,
                }">
                  {{ toLocal(user.created_at).format("lll") }}
                </td>

                <td
                  class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <router-link :to="{ path: `/users/${user.id}` }"
                    class="text-indigo-600 hover:text-indigo-900">View</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toLocal } from "@/helpers/date";
import { numberWithCommas, toTitleCase, optional, hasRole } from "@/helpers/global";
import { handleApiError } from "@/helpers/error";
import moment from "moment";

export default {
  title: "Dashboard",
  data() {
    return {
      loaded: false,
      loading: false,
      reference: null,
      dueDate: null,
      initial: {
        rate: 10,
        total_users: 526,
        total_payments: 404,
        total_subscriptions: 402,
        active_subscriptions: 24,
        gross_volume: 565512.55,
        net_volume: 552897.79,
        total_expense: 751887.2,
        payment_fee_sum: 12614.76,
        payment_amount_sum: 565512.55,
        mrr: 483676.52,
        active_users: 136,
        spend_per_customer: 4158.180514705883,
        margin: 201000,
        cac: 5528.582352941176,
        days: 31,
      },
      nextTarget: {},
    };
  },
  methods: {
    toLocal,
    numberWithCommas,
    toTitleCase,
    optional,
    hasRole,
    confirmPayment(reference) {
      let r = confirm(
        `Are you sure you wanna manually verify this payment with reference #${reference}?`
      );
      if (!r) return;
      this.loading = true;
      this.$store
        .dispatch("payments/confirmPayment", {
          reference,
          source: "paystack",
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to confirm payment");
        })
        .finally(() => {
          this.loading = false;
          this.reference = null;
        });
    },
    cumulativeTotal(duration, rate, initialAmount, initialDate, key, dueDate) {
      let initial = Number(initialAmount);
      let amounts = [
        {
          date: initialDate.format("YYYY-MM-DD"),
          amount: initial,
          cumulative: initial,
        },
      ];
      let payload = {};

      for (let i = 0; i < duration; i++) {
        let prev = amounts[amounts.length - 1];
        let amount = prev.amount + prev.amount * (rate / 100);
        payload = {
          date: toLocal(initialDate)
            .add(i + 1, "M")
            .format("YYYY-MM-DD"),
          prev: prev.amount,
          amount,
          cumulative: prev.cumulative + amount,
          days: Math.abs(
            moment(prev.date).diff(toLocal(initialDate).add(i + 1, "M"), "days")
          ),
        };
        if (dueDate === payload.date) {
          this.nextTarget[key] = payload;
          this.nextTarget[key]["days"] = Math.abs(
            moment().diff(this.nextTarget[key].date, "days")
          );
        }
        amounts.push(payload);
      }
    },
  },
  computed: {
    users() {
      return this.$store.getters["users/users"];
    },
    usersMeta() {
      return this.$store.getters["users/meta"];
    },
    subscriptions() {
      return this.$store.getters["subscriptions/subscriptions"];
    },
    subscriptionsMeta() {
      return this.$store.getters["subscriptions/meta"];
    },
    payments() {
      return this.$store.getters["payments/payments"];
    },
    paymentsMeta() {
      return this.$store.getters["payments/meta"];
    },
    report() {
      return this.$store.getters["reports/report"];
    },
    rates() {
      return this.$store.getters["global/rates"];
    },
    launchDate() {
      return this.$store.getters["global/launchDate"];
    },
  },
  created() {
    let today = moment();
    Promise.all([
      this.$store.dispatch("users/index", {
        sort: "created_at|desc",
        created_at_from: today.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        per_page: 5,
      }),
      (hasRole(this.$auth.user(), 'admin') || hasRole(this.$auth.user(), 'growth')) && this.$store.dispatch("reports/index", {}),
      this.$store.dispatch("subscriptions/index", {
        sort: "created_at|desc",
        created_at_from: today.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        per_page: 5,
      }),
      this.$store.dispatch("payments/index", {
        sort: "created_at|desc",
        created_at_from: today.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        per_page: 5,
      }),
    ]).finally(() => {
      this.loaded = true;
      let todayDate = today.format("DD");
      let launchDay = this.launchDate.split("-").pop();
      this.initial.rate = this.$route.query.rate || this.initial.rate;
      this.$route.query.currency = this.$route.query.currency || 'USD'
      this.initial.duration =
        this.$route.query.duration || (moment(today).diff(this.launchDate, 'months') + 1);
      this.dueDate =
        todayDate > launchDay
          ? moment(`${moment().add(1, "M").format("YYYY-MM")}-${launchDay}`)
          : moment(`${moment().format("YYYY-MM")}-${launchDay}`);
      Object.keys(this.initial).forEach((key) =>
        this.cumulativeTotal(
          this.initial.duration,
          this.initial.rate,
          this.initial[key],
          moment(this.launchDate).add(1, "M"),
          key,
          this.dueDate.format("YYYY-MM-DD")
        )
      );
    });
  },
};
</script>
<style scoped>
progress {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
}

::-webkit-progress-bar {
  background-color: orange;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>