export default {
  SET_BANK_ACCOUNTS(state, array) {
    state.bank_accounts = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_BANK_ACCOUNT(state, obj) {
    state.bank_account = obj;
  },
}
