var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ blur: !_vm.loaded }},[_c('h3',{staticClass:"text-gray-700 text-3xl font-semibold"},[_vm._v(" "+_vm._s(`Review Request ${_vm.review.reference}`)+" ")]),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"p-6 bg-white rounded-md shadow-md"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4"},[_c('div',[_vm._m(0),(_vm.review.user)?_c('router-link',{attrs:{"to":{ path: `/users/${_vm.review.user.id}` }}},[_vm._v(_vm._s(_vm.review.user.username)+" "),_c('br'),_c('span',{staticStyle:{"color":"brown"}},[_vm._v(_vm._s(_vm.review.user.first_name)+" "+_vm._s(_vm.review.user.last_name))])]):_vm._e()],1),_c('div',[_vm._m(1),(_vm.review.reviewer)?_c('router-link',{attrs:{"to":{ path: `/users/${_vm.review.reviewer.id}` }}},[_vm._v(_vm._s(_vm.review.reviewer.username))]):_vm._e()],1),_c('div',[_vm._m(2),(_vm.review.subscription)?_c('router-link',{attrs:{"to":{ path: `/subscriptions/${_vm.review.subscription.id}` }}},[_vm._v(_vm._s(_vm.review.subscription.reference))]):_vm._e()],1),_c('div',[_vm._m(3),(_vm.review.offer)?_c('router-link',{attrs:{"to":{ path: `/offers/${_vm.review.offer.id}` }}},[_vm._v(_vm._s(_vm.review.offer.reference)+" "),_c('br'),_c('span',{staticStyle:{"color":"brown"}},[_vm._v(_vm._s(_vm.review.offer.title))])]):_vm._e()],1),_c('div',[_vm._m(4),_vm._v(_vm._s(_vm.review.reference)+" ")]),_c('div',[_vm._m(5),_vm._v(_vm._s(_vm.review.review)+" ")]),_c('div',[_vm._m(6),_vm._v(" "+_vm._s(_vm.review.rating)+" ")]),_c('div',[_vm._m(7),_c('span',{},[_vm._v(_vm._s(_vm.toLocal(_vm.review.updated_at).format("lll")))])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("User : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Reviewer : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Subscription : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Offer : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Reference : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Message : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Rating: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Last Updated : ")])])
}]

export { render, staticRenderFns }