<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Conversation #${conversation.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Subject : </b></label
                >
                <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="conversation.subject"
                />
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Sender : </b></label
                >
                <router-link v-if="conversation.sender" :to="{ path: `/users/${conversation.sender_id}` }">{{
                  conversation.sender && conversation.sender.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Recipient : </b></label
                >
                <router-link v-if="conversation.recipient" :to="{ path: `/users/${conversation.recipient_id}` }">{{
                  conversation.recipient && conversation.recipient.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Messages : </b></label
                >
                <router-link   :to="{ path: `/messages`, query: { conversation_reference: conversation.reference } }">Messages</router-link>
              </div>




              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(conversation.updated_at).format("lll")
                }}</span>
              </div>

              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { toLocal } from "@/helpers/date";

export default {
  title:() => {
     return `Conversation #${window.location.href.split('/').pop()} | Conversations`
  },
  data() {
    return {
      loaded: false,
      loading: false,
    };
  },
  methods: {
    toLocal,
  
  },
  computed: {
    conversation() {
      return this.$store.getters["conversations/conversation"];
    },
  },
  created() {
    this.$store
      .dispatch("conversations/getSingle", {
        id: this.$route.params.id,

      })
      .finally(() => {
        this.loaded = true;
      });
  },
};
</script>
