import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const refundRequests = {
  namespaced: true,
  state: {
    refund_requests : [],
    refund_request : {},
    meta : {},
  },
  actions,
  getters,
  mutations
}
