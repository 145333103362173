/**
 * This would be the home of our stores.
 */
import { global } from './global/store';
import { admins } from './admins/store';
import { users } from './users/store';
import { payments } from './payments/store';
import { auditTrails } from './auditTrails/store';
import { subscriptions } from './subscriptions/store';
import { services } from './services/store';
import { offers } from './offers/store';
import { tasks } from './tasks/store';
import { expenses } from './expenses/store';
import { reports } from './reports/store';
import { coupons } from './coupons/store';
import { appIds } from './appIds/store';
import { tickets } from './tickets/store';
import { messaging } from './messaging/store';
import { messages } from './messages/store';
import { conversations } from './conversations/store';
import { refundRequests } from './refundRequests/store';
import { reviews } from './reviews/store';
import { settlements } from './settlements/store';
import { bankAccounts } from './bankAccounts/store';


export default {
  modules: {
      global,
      admins,
      payments,
      auditTrails,
      users,
      subscriptions,
      services,
      offers,
      tasks,
      expenses,
      reports,
      coupons,
      appIds,
      tickets,
      messages,
      messaging,
      conversations,
      refundRequests,
      reviews,
      settlements,
      bankAccounts,
  },

};
