<template>
  <div>
    <transition name="slide-fade">
      <div >
        <form class="d-form" @submit.prevent="save">
          <div class="grid grid-cols-6">
           
            <div>
              <span class="text-bold text-opacity-pale">Status</span>
              <multiselect
                @input="setFilters('status', params['status'])"
                v-model="params['status']"
                name="status"
                :options="['handling', 'resolved', 'pending', 'awaiting_buyer', 'awaiting_seller']"
                :show-labels="false"
              ></multiselect>
            </div>

            <div >
              <span class="text-bold text-opacity-pale">Created At (From)</span>
              <input
              type="date"
                v-model="params['created_at_from']"
                @input="setFilters('created_at_from',params['created_at_from'])"
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"


              />
            </div>

               <div >
              <span class="text-bold text-opacity-pale">Created At (To)</span>
              <input
              type="date"
              class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"

                v-model="params['created_at_to']"
                @input="setFilters('created_at_to',params['created_at_to'])"
                name="created_at_to"
              />
            </div>

              <div >
              <span class="text-bold text-opacity-pale">Per Page</span>
               <multiselect
                      :close-on-select="false"
                @input="setFilters('per_page',params['per_page'])"
                v-model="params['per_page']"
                name="per_page"
                :options="[50, 100, 250, 500, 1000]"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from 'vue-debounce'
export default {
  props: ["params", "visibleFilter"],
  methods: {
    setFilters(name, val) {
      val = typeof val === "object" ? (val && val.id ? val.id : val) : val;
      let data = {
        name,
        val
      };
      this.$emit("setFilters", data);
    },
     dFn(param) {
      const vm = this
      debounce( function(param) {
        vm.setFilters(param.name, typeof param.val === 'string' ? param.val.trim() : param.val)
      }, '400ms')(param)
    }
  },
  computed: {
 
  },
  created() {
   
  },
};
</script>
