<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Subscription #${subscription.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="() => {}">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reference : </b></label
                >
                <span class="">{{ subscription.reference }} </span>
              </div>
              
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Service : </b></label
                >
                <router-link
                  :to="{ path: `/services/${subscription.service_id}` }"
                  >{{
                    subscription.service && subscription.service.name
                  }}</router-link
                >
              </div>

              <div v-if="subscription.offer">
                <label class="text-gray-700" for="title"
                  ><b>Offer : </b></label
                >
                <router-link
                  :to="{ path: `/offers/${subscription.offer.id}` }"
                  >{{
                    subscription.offer && subscription.offer.reference
                  }}</router-link
                >
              </div>


              <div>
                <label class="text-gray-700" for="title"><b>User : </b></label>
                <router-link
                  v-if="subscription.user"
                  :to="{ path: `/users/${subscription.user_id}` }"
                >
                  <span class="text-red-500">
                    {{
                      `${subscription.user.first_name || ''} ${subscription.user.last_name || ''}`
                    }}
                    <small class="text-blue-500"> ({{
                      subscription.user.email
                    }})</small></span
                  ></router-link
                >
              </div>

              <div v-if="subscription.offer?.subplan || subscription.subplan">
                <label class="text-gray-700" for="title"><b>Subplan : </b></label>
                <span class=""
                  >{{
                   subscription.offer.subplan || subscription.subplan
                  }}
                </span>
              </div>
             
              <div v-if="subscription.payment">
                <label class="text-gray-700" for="title"
                  ><b>Amount : </b></label
                >
                <span class="">
                  {{
                    subscription.payment &&
                    subscription.offer.currency &&
                    subscription.payment.currency.symbol
                  }}{{ numberWithCommas(subscription.payment.amount) }}
                </span>
              </div>

              <div >
                <label class="text-gray-700" for="title"
                  ><b>Duration : </b></label
                >
                <span class="">{{ subscription.months }} </span>
              </div>

              <div >
                <label class="text-gray-700" for="status"
                  ><b>Status : </b></label
                >
                <span class="">{{ subscription.status }} </span>
              </div>

              <div >
                <label class="text-gray-700" for="title"
                  ><b>Renewals : </b></label
                >
                {{ subscription.renewals }}
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Creation Date : </b></label
                >
                <span class="">{{
                  toLocal(subscription.created_at).format("lll")
                }}</span>
              </div>

              <div v-if="subscription.cancelled_at">
                <label class="text-gray-700" for="title"
                  ><b>Cancelled At : </b></label
                >
                <span class=""
                  >{{ toLocal(subscription.cancelled_at).format("lll") }}
                  <small
                    >by
                    {{
                      subscription.cancelled_by_user ? "user" : "system"
                    }}</small
                  ></span
                >
              </div>


              <div v-if="subscription.cancel_reason">
                <label class="text-gray-700" for="title"
                  ><b>Cancel Reason: </b></label
                >
                <span class="">{{ subscription.cancel_reason }}</span>
              </div>

              <div v-if="subscription.refund_requested_at && subscription.status !== 'refunded'">
                <label class="text-gray-700" for="title"
                  ><b>Refund Requested At : </b></label
                >
                <span class=""
                  >{{ toLocal(subscription.refund_requested_at).format("lll") }}
                  </span
                >
              </div>

              <div >
                <label class="text-gray-700" for="title"
                  ><b>Expires At : </b></label
                >
                <span class="">{{
                 subscription.expires_at ? toLocal(subscription.expires_at).format("lll") : 'N/A'
                }}</span>
              </div>

                 <div>
                <label class="text-gray-700" for="title"
                  ><b>Updated At : </b></label
                >
                <span class="">{{
                 toLocal(subscription.updated_at).format("lll")
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Payments : </b></label
                >
                <router-link :to="{ path: `/payments`, query: { subscription_id: subscription.id}}">View Payments</router-link>
              </div>


              <div
                v-if="
                  subscription.service_email
                "
              >
                <label class="text-gray-700" for="service_email"
                  ><b
                    >{{ subscription.offer && subscription.offer.detail_name }}
                    :
                  </b></label
                >
                <span class="">{{
                 subscription.service_email
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Update Detail : </b
                  > <small><i>(required to update subscription)</i></small></label
                >
                <wysiwyg
                  name="details"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.details"
                />
              </div>


               <div>
                <label class="text-gray-700" for="title"><b>Status : </b></label>
                <select
                  name="status"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.status"
                >
                <option value="delivered">Delivered</option>
                <option value="active">Active</option>
                <option value="processing">Processing</option>
                </select>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b> Chat : </b></label>
              <span class="" v-if="!subscription.offer?.seeded">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{
                      path: `/conversations`,
                      query: { subscription_id: subscription.id },
                    }"
                    >Goto Conversation</router-link
                  >
                </span>
                <span class="" v-else>
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{
                      path: `/messaging`,
                      query: { subscription_id: subscription.id },
                    }"
                    >Goto Conversation</router-link
                  >
                </span>
                </div>

               <div >
                <label class="text-gray-700" for="title"><b>Cancel Reason : </b><small><i>(required to cancel subscription)</i></small></label>
                <textarea
                  name="reason"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.reason"
                ></textarea>
              </div>

              <div
                class="flex justify-end mt-4"
               
              >
                <button v-if="subscription.offer?.seeded"
                 
                  :disabled="!subscription.offer?.seeded || loading"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ subscription.offer?.seeded ? "Update Subscription" : 'Not Internal' }}
                </button>

                <button v-if="subscription.offer?.seeded"
                 
                 :disabled="!subscription.offer?.seeded || loading"
                 @click="cancel"
                 :class="`mx-4 px-4 py-2 bg-red-${
                   loading ? 200 : 800
                 } text-red-200 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-${
                   loading ? 100 : 700
                 }`"
               >
                 {{ subscription.offer?.seeded ? "Cancel & Refund" : 'Not Internal' }}
               </button>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";
import { optional, numberWithCommas } from "@/helpers/global";

export default {
  title: () => {
    return `Subscription #${window.location.href
      .split("/")
      .pop()} | Subscriptions`;
  },
  data() {
    return {
      loaded: false,
      loading: false,
      body: {},
    };
  },
  methods: {
    toLocal,
    optional,
    numberWithCommas,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let r = confirm(`Are you sure you want to update this subscription and set status as ${this.body.status}?`)
      if(!r) {
        this.loading = false;
        return
      }
      this.$store
        .dispatch("subscriptions/update", this.body)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update subscription");
        })
        .finally(() => (this.loading = false));
    },
    cancel() {
      let r = confirm(`Are you sure you want to cancel this subscription and refund buyer?`)
      if(!r) {
        this.loading = false;
        return
      }
      this.$store
        .dispatch("subscriptions/cancel", this.body)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to cancel subscription");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    subscription() {
      return this.$store.getters["subscriptions/subscription"];
    },
  },
  created() {
    this.$store
      .dispatch("subscriptions/getSingle", {
        id: this.$route.params.id,
        include: "user,service",
      })
      .finally(() => {
        this.body = {
          id: this.subscription.id,
          status: this.subscription.status,
          reason: this.subscription.cancel_reason,
          active: this.subscription.active,
          service_email: this.subscription.service_email,
          details: this.subscription.details,
        }
        this.loaded = true;
      });
  },
};
</script>
