import axios from 'axios';


const API = `${process.env.VUE_APP_BASE_URI}/admin`;

export default {
    index({
        commit
    }, { include = '', per_page = 5, sort = '' }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks?sort=${sort}&per_page=${per_page}&include=${include}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {

                    commit('SET_TASKS', data.data);
                    commit('SET_META', data.meta);
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
  
    update({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks/${id}`;
            axios.patch(url)
                .then( ({
                    data
                }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },

}
