export default {
  SET_OFFER(state, array) {
    state.offer = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_OFFERS(state, obj) {
    state.offers = obj;
  },
}
