import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const settlements = {
  namespaced: true,
  state: {
    settlements : [],
    settlement : {},
    meta : {},
  },
  actions,
  getters,
  mutations
}
