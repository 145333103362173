import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const reviews = {
  namespaced: true,
  state: {
    reviews : [],
    review : {},
    meta : {},
  },
  actions,
  getters,
  mutations
}
