export default {
  SET_REFUND_REQUESTS(state, array) {
    state.refund_requests = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_REFUND_REQUEST(state, obj) {
    state.refund_request = obj;
  },
}
