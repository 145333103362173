<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Convo #${conversation.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reference : </b></label
                >
                <span
          
                  class=""
                  
                >{{  conversation.reference }}</span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Sender : </b></label
                >
                <router-link v-if="conversation.sender" :to="{ path: `/users/${conversation.sender_id}` }">{{
                  conversation.sender && conversation.sender.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Recipient : </b></label
                >
                <router-link v-if="conversation.recipient" :to="{ path: `/users/${conversation.recipient_id}` }">{{
                  conversation.recipient && conversation.recipient.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Messages : </b></label
                >
                <router-link    :to="{ path: `/messages`, query: { conversation_reference: conversation.reference } }">Messages</router-link>
              </div>


              <div>
                <label class="text-gray-700" for="title"><b>Add Message : </b></label>
                <wysiwyg
                  
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="body.message"
                />
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(conversation.updated_at).format("lll")
                }}</span>
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading || !conversation.seeded"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ loading ? 'Sending...' : conversation.seeded ? 'Send Message' : 'Cannot send' }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title:() => {
     return `Messaging #${window.location.href.split('/').pop()} | Messaging`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      body: {}
    };
  },
  methods: {
    toLocal,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.body);

      this.$store
        .dispatch("messaging/addMessage", data)
        .then(({ message }) => {
          this.body.message = ''
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to send reply");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    conversation() {
      return this.$store.getters["messaging/conversation"];
    },
  },
  created() {
    this.$store
      .dispatch("messaging/getSingle", {
        reference: this.$route.params.reference,

      })
      .finally(() => {
        this.loaded = true;
        this.body = {
          reference: this.$route.params.reference
        }
      });
  },
};
</script>
