export default {
  SET_CONVERSATIONS(state, array) {
    state.conversations = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_CONVERSATION(state, obj) {
    state.conversation = obj;
  },
}
