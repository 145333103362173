var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ blur: !_vm.loaded }},[_c('h3',{staticClass:"text-gray-700 text-3xl font-semibold"},[_vm._v(" "+_vm._s(`Convo #${_vm.conversation.reference}`)+" ")]),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"p-6 bg-white rounded-md shadow-md"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4"},[_c('div',[_vm._m(0),_c('span',{},[_vm._v(_vm._s(_vm.conversation.reference))])]),_c('div',[_vm._m(1),(_vm.conversation.sender)?_c('router-link',{attrs:{"to":{ path: `/users/${_vm.conversation.sender_id}` }}},[_vm._v(_vm._s(_vm.conversation.sender && _vm.conversation.sender.username))]):_vm._e()],1),_c('div',[_vm._m(2),(_vm.conversation.recipient)?_c('router-link',{attrs:{"to":{ path: `/users/${_vm.conversation.recipient_id}` }}},[_vm._v(_vm._s(_vm.conversation.recipient && _vm.conversation.recipient.username))]):_vm._e()],1),_c('div',[_vm._m(3),_c('router-link',{attrs:{"to":{ path: `/messages`, query: { conversation_reference: _vm.conversation.reference } }}},[_vm._v("Messages")])],1),_c('div',[_vm._m(4),_c('wysiwyg',{staticClass:"form-input w-full mt-2 rounded-md focus:border-indigo-600",model:{value:(_vm.body.message),callback:function ($$v) {_vm.$set(_vm.body, "message", $$v)},expression:"body.message"}})],1),_c('div',[_vm._m(5),_c('span',{},[_vm._v(_vm._s(_vm.toLocal(_vm.conversation.updated_at).format("lll")))])]),_c('div',{staticClass:"flex justify-end mt-4"},[_c('button',{class:`px-4 py-2 bg-gray-${
                  _vm.loading ? 200 : 800
                } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                  _vm.loading ? 100 : 700
                }`,attrs:{"disabled":_vm.loading || !_vm.conversation.seeded},on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.loading ? 'Sending...' : _vm.conversation.seeded ? 'Send Message' : 'Cannot send')+" ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Reference : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Sender : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Recipient : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Messages : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Add Message : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-gray-700",attrs:{"for":"title"}},[_c('b',[_vm._v("Last Updated : ")])])
}]

export { render, staticRenderFns }