<template>
  <div :class="{ blur: !loaded }">
    <div class="p-2">
      <h3 class="text-gray-700 text-3xl font-semibold">Service Report</h3>
      <h5>
        {{ toLocal(moreParams.created_at_from).format("ll") }} to
        {{ toLocal(moreParams.created_at_to).format("ll") }}
      </h5>
      <h6>
        <small
          ><i>Generated at {{ toLocal().format("lll") }}</i></small
        >
      </h6>
    </div>

    <div>
      <transition name="slide-fade">
        <div>
          <form class="d-form" @submit.prevent>
            <div class="grid grid-cols-6">
              <div>
                <span class="text-bold text-opacity-pale">Group By</span>
                <multiselect
                  v-model="moreParams['group_by']"
                  name="group_by"
                  :options="[
                    'YEAR(created_at)',
                    'MONTH(created_at)',
                    'WEEK(created_at)',
                    'DATE(created_at)',
                  ]"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Year</span>
                <multiselect
                  :multiple="false"
                  v-model="moreParams['year']"
                  name="year"
                  :options="[2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Month</span>
                <multiselect
                  :multiple="false"
                  v-model="moreParams['month']"
                  name="month"
                  :options="Object.values(months)"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Currency</span>
                <multiselect
                  :multiple="false"
                  name="currency"
                  @input="setChartData(apiData)"
                  v-model="currency"
                  :options="['USD', 'EUR']"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale"
                  >Created At (From)</span
                >
                <input
                  type="date"
                  v-model="moreParams['created_at_from']"
                  class="
                    appearance-none
                    rounded-r rounded-l
                    sm:rounded-l-none
                    border border-gray-400 border-b
                    block
                    pl-8
                    pr-6
                    py-2
                    bg-white
                    text-sm
                    placeholder-gray-400
                    text-gray-700
                    focus:bg-white focus:placeholder-gray-600
                  "
                />
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Created At (To)</span>
                <input
                  type="date"
                  class="
                    appearance-none
                    rounded-r rounded-l
                    sm:rounded-l-none
                    border border-gray-400 border-b
                    block
                    pl-8
                    pr-6
                    py-2
                    bg-white
                    text-sm
                    placeholder-gray-400
                    text-gray-700
                    focus:bg-white focus:placeholder-gray-600
                  "
                  v-model="moreParams['created_at_to']"
                  name="created_at_to"
                />
              </div>
            </div>
          </form>
        </div>
      </transition>
    </div>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md" v-if="chartData2">
          <Bar :chart-data="chartData2" />
        </div>
      </div>
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md" v-if="chartData3">
          <Doughnut :chart-data="chartData3" />
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script >
import { optional } from "@/helpers/global";
import { toLocal, getDateRangeOfWeek } from "@/helpers/date";
import Doughnut from "@/components/Chart/Doughnut.vue";
import Bar from "@/components/Chart/Bar.vue";
import moment from "moment";

export default {
  title: "Service | Reports",
  components: {
    Doughnut,
    Bar,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      chartData2: null,
      chartData3: null,
      currency: null,
      apiData: [],
      moreParams: {
        group_by: "WEEK(created_at)",
        year: null,
        month: null,
        created_at_from: null,
        created_at_to: null,
      },
    };
  },
  methods: {
    toLocal,
    optional,
    formatLabel(datum, groupBy) {
      if (groupBy == "MONTH(created_at)") {
        return this.months[datum[groupBy]];
      } else if (groupBy == "YEAR(created_at)") {
        return datum[groupBy];
      } else if (groupBy == "WEEK(created_at)") {
        return getDateRangeOfWeek(datum[groupBy], this.moreParams.year);
      } else {
        return new Date(datum[groupBy]).toLocaleDateString();
      }
    },
    setChartData(data) {
      this.chartData2 = null;
      let labels = [];
      let datasets2 = [
        {
          label: `Service Amount (${
            this.currency ? this.rates[this.currency].symbol : "₦"
          }) `,
          backgroundColor: [],
          data: [],
        },
      ];

      for (let datum of data) {
        let service = this.services.find((s) => s.id == datum["service_id"]);
        labels.push(service ? service.name : datum["service_id"]);
        datasets2[0].backgroundColor.push(
          "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0")
        );
        datasets2[0].data.push(
          this.currency
            ? datum["totalAmount"] / this.rates[this.currency].rate
            : datum["totalAmount"]
        );
      }
      this.chartData2 = {
        labels,
        datasets: datasets2,
      };
    },
    setDoughnutData(data) {
      let labels = [];
      let sum = data.reduce((a, b) => a + optional(b, 'totalCount', 0), 0);
      let datasets = [
        {
          label: "Services",
          backgroundColor: [],
          data: [],
        },
      ];
      for (let datum of data) {
        let service = this.services.find((s) => s.id == datum["service_id"]);
        labels.push(
          `${service ? service.name : datum["service_id"]} (${parseFloat(
            (optional(datum, 'totalCount', 0) / sum) * 100
          ).toFixed(2)}%)`
        );
        datasets[0].data.push(optional(datum, 'totalCount', 0));
        datasets[0].backgroundColor.push(
          "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0")
        );
      }
      this.chartData3 = {
        labels,
        datasets,
      };
    },
  },
  watch: {
    moreParams: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.loaded = false;
        this.chartData1 = null;
        this.chartData2 = null;
        this.$store.dispatch("services/getReport", newValue).then((data) => {
          this.apiData = data;
          this.setChartData(data);
          this.setDoughnutData(data)
          this.loaded = true;
        });
      },
      deep: true,
    },
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
    rates() {
      return this.$store.getters["global/rates"];
    },
    months() {
      return this.$store.getters["global/months"];
    },
  },
  created() {
    let launchDate = moment(this.$store.getters["global/launchDate"]).startOf(
      "day"
    );
    let threeMonthsAgo = moment().subtract(3, "months").startOf("day");
    this.moreParams.created_at_from =
      launchDate.unix() > threeMonthsAgo.unix()
        ? launchDate.format("YYYY-MM-DD")
        : threeMonthsAgo.format("YYYY-MM-DD");
    this.moreParams.created_at_to = moment().format("YYYY-MM-DD");
    this.moreParams.year = new Date().getFullYear();

    Promise.resolve(
      this.services.length || this.$store.dispatch("services/index", { per_page: 1000 })
    ).then(() => {
      this.loaded = true;
    });
  },
};
</script>
    