import axios from 'axios';


const API = `${process.env.VUE_APP_BASE_URI}/admin`;

export default {
    index({
        commit
    }, obj) {
        return new Promise((resolve, reject) => {
            let url = `${API}/conversations?${new URLSearchParams(Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))).toString()}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {

                    commit('SET_CONVERSATIONS', data.data);
                    commit('SET_META', data.meta);
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    getSingle({
        commit
    }, { reference, include = '' }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/conversations/${reference}?include=${include}`;
            axios.get(url)
                .then(async ({
                    data: { data }
                }) => {
                    commit('SET_CONVERSATION', data);

                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },


}
