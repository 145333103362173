export default {
  SET_SETTLEMENTS(state, array) {
    state.settlements = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_SETTLEMENT(state, obj) {
    state.settlement = obj;
  },
}
