<template>
  <div>
    <transition name="slide-fade">
      <div>
        <form class="d-form" @submit.prevent="save">
          <div class="grid grid-cols-4">
            <div>
              <span class="text-bold text-opacity-pale">Service</span>
              <multiselect
                track-by="id"
                return="id"
                label="name"
                :multiple="true"
                @input="setFilters('service', params['service'])"
                v-model="params['service']"
                name="role"
                :options="services"
                :show-labels="false"
              ></multiselect>
            </div>
             <div>
              <span class="text-bold text-opacity-pale">Reference</span>
              <input
                @input="dFn({name: 'reference', val: params['reference']})"
                v-model="params['reference']"
                name="reference"
                 class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
              />
            </div>
             <div>
              <span class="text-bold text-opacity-pale">Email</span>
              <input
                @input="dFn({name: 'email', val: params['email']})"
                v-model="params['email']"
                name="email"
                 class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
              />
            </div>
            <div>
              <span class="text-bold text-opacity-pale">Status</span>
              <multiselect
                @input="setFilters('status', params['status'])"
                v-model="params['status']"
                name="status"
                :options="['processing', 'pending', 'active', 'delivered', 'inactive', 'cancelled', 'refunded', 'expired']"
                :show-labels="false"
              ></multiselect>
            </div>

            <div v-if="!['expired', 'cancelled'].includes(type)">
              <span class="text-bold text-opacity-pale">Created At (From)</span>
              <input
                type="date"
                v-model="params['created_at_from']"
                @input="
                  setFilters('created_at_from', params['created_at_from'])
                "
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
              />
            </div>

            <div v-if="!['expired', 'cancelled'].includes(type)">
              <span class="text-bold text-opacity-pale">Created At (To)</span>
              <input
                type="date"
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
                v-model="params['created_at_to']"
                @input="setFilters('created_at_to', params['created_at_to'])"
                name="created_at_to"
              />
            </div>

            <div>
              <span class="text-bold text-opacity-pale">Per Page</span>
              <multiselect
                :close-on-select="false"
                @input="setFilters('per_page', params['per_page'])"
                v-model="params['per_page']"
                name="per_page"
                :options="[50, 100, 250, 500, 1000]"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="checkbox-wrapper" v-if="!['expired', 'cancelled'].includes(type)">
              <label class=""
                >Recurring
                <input
                  type="checkbox"
                  @change="setFilters('recurring', params['recurring'])"
                  v-model="params['recurring']"
                />
              </label>
            </div>
            <div class="checkbox-wrapper" v-if="!['expired', 'cancelled'].includes(type)">
              <label class=""
                >Active
                <input
                  type="checkbox"
                  @change="setFilters('active', params['active'])"
                  v-model="params['active']"
                />
              </label>
            </div>
            <div class="checkbox-wrapper" >
              <label class=""
                >Internal
                <input
                  type="checkbox"
                  @change="setFilters('internal', params['internal'])"
                  v-model="params['internal']"
                />
              </label>
            </div>
            <div v-if="type == 'expired'">
              <span class="text-bold text-opacity-pale">Expires At (From)</span>
              <input
                type="date"
                v-model="params['expires_at_from']"
                @input="
                  setFilters('expires_at_from', params['expires_at_from'])
                "
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
              />
            </div>

            <div v-if="type == 'expired'">
              <span class="text-bold text-opacity-pale">Expires At (To)</span>
              <input
                type="date"
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
                v-model="params['expires_at_to']"
                @input="setFilters('expires_at_to', params['expires_at_to'])"
                name="expires_at_to"
              />
            </div>

            <div v-if="type == 'cancelled'">
              <span class="text-bold text-opacity-pale">Cancelled At (From)</span>
              <input
                type="date"
                name="cancelled_at_from"
                v-model="params['cancelled_at_from']"
                @input="
                  setFilters('cancelled_at_from', params['cancelled_at_from'])
                "
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
              />
            </div>

            <div v-if="type == 'cancelled'">
              <span class="text-bold text-opacity-pale">Cancelled At (To)</span>
              <input
                type="date"
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
                v-model="params['cancelled_at_to']"
                @input="setFilters('cancelled_at_to', params['cancelled_at_to'])"
                name="cancelled_at_to"
              />
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from 'vue-debounce'
export default {
  props: ["params", "visibleFilter", "type"],
  methods: {
    setFilters(name, val) {
      val = typeof val === "object" ? (val && val.id ? val.id : val) : val;
      let data = {
        name,
        val,
      };
      this.$emit("setFilters", data);
    },
    dFn(param) {
      const vm = this
      debounce( function(param) {
         vm.setFilters(param.name, typeof param.val === 'string' ? param.val.trim() : param.val)
      }, '400ms')(param)
    }
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
  },
  created() {
    this.services.length || this.$store.dispatch("services/index", { per_page: 1000 } );
  },
};
</script>
