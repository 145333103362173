import axios from 'axios';


const API = process.env.VUE_APP_BASE_URI;

export default {
  getCountries({
    commit
  }) {
    return new Promise((resolve, reject) => {
      let url = `${API}/countries?include=regions`;
      axios.get(url)
        .then(async ({
          data : {data}
        }) => {
          commit('SET_COUNTRIES', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error.response)
        });
    });
  },
  getCurrencies({
    commit
  }) {
    return new Promise((resolve, reject) => {
      let url = `${API}/currencies`;
      axios.get(url)
        .then(async ({
          data : {data}
        }) => {
          commit('SET_CURRENCIES', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error.response)
        });
    });
  },

  getRoles({
    commit
  }) {
    return new Promise((resolve, reject) => {
      let url = `${API}/admin/roles`;
      axios.get(url)
        .then(async ({
          data : {data}
        }) => {
          commit('SET_ROLES', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error.response)
        });
    });
  },
  getBanks({
    commit
  }) {
    return new Promise((resolve, reject) => {
      let url = `${API}/banks`;
      axios.get(url)
        .then(async ({
          data : {data}
        }) => {
          commit('SET_BANKS', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error.response)
        });
    });
  },

  updateProfile({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      let url = `${API}/auth/complete`;
      axios.patch(url, data)
        .then(async ({
          data
        }) => {

          resolve(data);
        })
        .catch((error) => {
          reject(error.response)
        });
    });
  },
  updatePassword({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      let url = `${API}/auth/password`;
      axios.patch(url, data)
        .then(async ({
          data
        }) => {

          resolve(data);
        })
        .catch((error) => {
          reject(error.response)
        });
    });
  },
}
