import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const bankAccounts = {
  namespaced: true,
  state: {
    bank_accounts : [],
    bank_account : {},
    meta : {},
  },
  actions,
  getters,
  mutations
}
