<template>
  <div class="flex justify-center items-center h-screen bg-gray-200 px-6">
    <div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
      <div class="flex justify-center items-center">
         <img src="@/assets/img/logo.png" width="50px" height="50px" :alt="appName">

      </div>

      <form class="mt-4" @submit.prevent="login">
        <label class="block">
          <span class="text-gray-700 text-sm">Email</span>
          <input
            type="email"
            class="form-input mt-1 block w-full rounded-md focus:border-indigo-600"
            v-model="body.email"
            name="email"
            v-validate="'required|email'"
            data-vv-as="email"
            placeholder="Enter your Email"
          />
          <small class="form-text text-danger" v-show="errors.has('email')">{{
            errors.first("email")
          }}</small>
        </label>

        <label class="block mt-3">
          <span class="text-gray-700 text-sm">Password</span>
          <div class="mt-2 relative rounded-md shadow-sm">
          <input
            :type="passwordType"
            class="form-input mt-1 block w-full rounded-md focus:border-indigo-600"
            v-model="body.password"
            name="password"
            v-validate="'required|min:8'"
            data-vv-as="password"
            placeholder="Enter your Password"
          />


         <span @click="togglePassword" class="show-password absolute p-4 inset-y-0 right-0 pl-3 flex items-center text-gray-600">
           <i :class="passwordType === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"></i>
            </span>
         </div>


        </label>

        <div class="flex justify-between items-center mt-4">
          <div>
            <label class="inline-flex items-center">
              <input type="checkbox" class="form-checkbox text-indigo-600" />
              <span class="mx-2 text-gray-600 text-sm">Remember me</span>
            </label>
          </div>

          <div>
            <a
              class="block text-sm fontme text-indigo-700 hover:underline"
              href="#"
              >Forgot your password?</a
            >
          </div>
        </div>

        <div class="mt-6">
          <button
            :disabled="loading"
            type="submit"
            :class="`py-2 px-4 text-center bg-indigo-${loading ? 200 : 600} rounded-md w-full text-white text-sm hover:bg-indigo-${loading ? 100 : 500}`"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { handleApiError } from "@/helpers/error";

export default {
  title: "Login",
  data() {
    return {
      passwordType: "password",
      body: {
        email: "",
        password: "",
        is_admin: true
      },
      loading: false,
      appName: process.env.VUE_APP_NAME,
       api: process.env.VUE_APP_BASE_URI
    };
  },

  methods: {
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    login() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      this.$auth
        .login({
          data: this.body,
          rememberMe: this.body.rememberMe,
          staySignedIn: this.body.rememberMe,
            redirect: { name: "Dashboard" },
          staySignedIn: true,
          fetchUser: true
        })
        .then(res => {})
        .catch(err => {
          let response = err.response;
          handleApiError(
            this,
            response,
            response && response.data && response.data.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.$auth.check() && this.$auth.logout();
  }
};
</script>
