<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Refund Request ${refund.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="() => {}">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              
              <div v-if="refund.subscription?.service">
                <label class="text-gray-700" for="title"
                  ><b>Service : </b></label
                >
                <router-link v-if="refund.subscription?.service" :to="{ path: `/services/${refund.subscription.service.id}` }">{{
                  refund.subscription.service.name
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>User : </b></label
                >
                <router-link v-if="refund.subscription?.user" :to="{ path: `/users/${refund.subscription.user_id}` }">{{
                  refund.subscription.user.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Subscription : </b></label
                >
                <router-link v-if="refund.subscription"  :to="{ path: `/subscriptions/${refund.subscription.id}` }">{{
                  refund.subscription.reference
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reference : </b></label
                >{{
                  refund.reference
                }}
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reason : </b></label
                >{{
                  refund.reason
                }}
              </div>

              <div v-if="refund.status !== 'auto_approved'">
                <label class="text-gray-700" for="title"><b>Status : </b></label>
                <select
                  name="status"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="refund.status"
                >
                <option value="rejected">Rejected</option>
                <option value="approved">Approved</option>
                </select>
              </div>
             
         
      
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Status : </b></label
                >
                <span  >{{  refund.status }}</span>
              </div>

        
      
              <div>
                <label class="text-gray-700" for="title"><b>Amount: </b></label>
                {{ refund.payment?.currency?.symbol }}{{ refund.payment?.amount }}
              </div>

            
              <div>
                <label class="text-gray-700" for="title"><b>Reason : </b></label>
                {{refund.message}}
             
              </div>

              <div v-if="refund.seeded && refund.status !== 'auto_approved'">
                <label class="text-gray-700" for="title"><b>Response : </b></label>
                <textarea
                  
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="refund.reason"
                ></textarea>
              </div>


              <div v-else>
                <label class="text-gray-700" for="title"><b>Response : </b></label>
                {{refund.reason}}
             
              </div>
            
         
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(refund.updated_at).format("lll")
                }}</span>
              </div>

              <div class="flex justify-end mt-4">
                <button v-if="currentRefund.seeded && currentRefund.status !== 'auto_approved' && currentRefund.status !== 'approved'"
                  :disabled="loading || currentRefund.status !== 'pending'"
                  @click="approve"
                  :class="`px-4 py-2 bg-red-${
                    loading ? 200 : 800
                  } text-red-200 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ loading ? 'Approving...' : currentRefund.status !== 'pending' ? `Is ${currentRefund.status}`  : 'Approve' }}
                </button>

                <button v-if="currentRefund.seeded && currentRefund.status !== 'auto_approved' && currentRefund.status !== 'rejected'"
                  :disabled="loading || currentRefund.status !== 'pending'"
                  @click="reject"
                  :class="`mx-4 px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ loading ? 'Rejecting...' : currentRefund.status !== 'pending' ? `Is ${currentRefund.status}` : 'Reject' }}
                </button>
                
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";

export default {
  title:() => {
     return `Refund Request ${window.location.href.split('/').pop()} | Refund Request`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      refund: {
        meta: {
          card: null,
          phone: null,
        }
      },
    };
  },
  methods: {
    toLocal,
    approve() {
      this.loading = true;
      let r = confirm(`Are you sure you want to approve this refund request?`)
      if(!r) {
        this.loading = false;
        return
      }
    
      this.$store
        .dispatch("refundRequests/approve", {
          reference: this.$route.params.reference,
          reason: '',
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to approve this refund request");
        })
        .finally(() => (this.loading = false));
    },
    reject() {
      this.loading = true;
      let r = confirm(`Are you sure you want to approve this reject this refund request?`)
      if(!r) {
        this.loading = false;
        return
      }
    
      this.$store
        .dispatch("refundRequests/decline", {
          reference: this.$route.params.reference,
          message: this.refund.reason,
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to reject refund request");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    currentRefund() {
      return this.$store.getters["refundRequests/refund_request"];
    },
  },
  created() {
    this.$store
      .dispatch("refundRequests/getSingle", {
        reference: this.$route.params.reference,
      })
      .finally(() => {
        Object.assign(this.refund, this.currentRefund)
        this.loaded = true;
      });
  },
};
</script>
