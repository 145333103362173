import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const tickets = {
  namespaced: true,
  state: {
    tickets : [],
    ticket : {},
    meta : {},
    messages: [],
  },
  actions,
  getters,
  mutations
}
