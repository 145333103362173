import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const messages = {
  namespaced: true,
  state: {
    messages : [],
    message : {},
    meta : {},
  },
  actions,
  getters,
  mutations
}
