export default {
  SET_MESSAGE(state, array) {
    state.messages = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_MESSAGE(state, obj) {
    state.message = obj;
  },
}
