import axios from 'axios';


const API = `${process.env.VUE_APP_BASE_URI}/admin`;

export default {
    index({
        commit
    }, obj) {
        return new Promise((resolve, reject) => {
            let url = `${API}/users?${new URLSearchParams(Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))).toString()}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {
                    commit('SET_USERS', data.data);
                    delete data.data;
                    commit('SET_META', data.meta);
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    getSingle({
        commit
    }, { id, include = '' }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/users/${id}?include=${include}`;
            axios.get(url)
                .then(async ({
                    data: { data }
                }) => {
                    commit('SET_USER', data);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    updateStatus({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/users/${data.id}/update_status`;
            axios.patch(url, data)
                .then(async ({
                    data: { data, message }
                }) => {
                    commit('SET_USER', data);
                    resolve({ data, message });
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    getReport({
        commit
    }, obj = {}) {
        return new Promise((resolve, reject) => {
            
            let url = `${API}/users/report?${new URLSearchParams(Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))).toString()}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {

                    commit('SET_REPORT', data.data);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
}
