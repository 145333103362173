<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Payment #${payment.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
            <div>
              <label class="text-gray-700" for="title"><b>User : </b></label>
              <router-link :to="{ path: `/users/${payment.user_id}` }">{{
                payment.user && payment.user.first_name || ''
              }}</router-link>
            </div>

            <div v-if="payment.type !== 'credit'">
              <label class="text-gray-700" for="title"
                ><b>Subscription : </b></label
              >
              <router-link v-if="payment.subscription_id"
                :to="{ path: `/subscriptions/${payment.subscription_id}` }"
                >{{
                  payment.subscription && payment.subscription.reference
                }}</router-link
              >
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Description : </b></label
              >
              <span class="">{{ payment.description }}</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Type : </b></label
              >
              <span class="">{{ payment.type }}</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Status : </b></label
              >
              <span :class="`bg-${payment.status === 'successful' ? 'green' : 'red'}-100 border border-${payment.status === 'successful' ? 'green' : 'red'}-400 text-${payment.status === 'successful' ? 'green' : 'red'}-700 px-4 py-3 rounded relative`">{{ payment.status }}</span><small v-if="optional(payment, 'meta.refund.reason')">: <i>{{ optional(payment, 'meta.refund.reason') }}</i></small>
            </div>

            <div v-if="optional(payment, 'meta.refund')">
              <label class="text-gray-700" for="title"
                ><b>Refund Status : </b></label
              >
              <span :class="`bg-${payment.meta.refund.status === 'pending' ? 'green' : 'red'}-100 border border-${payment.meta.refund.status === 'pending' ? 'green' : 'red'}-400 text-${payment.meta.refund.status === 'pending' ? 'green' : 'red'}-700 px-4 py-3 rounded relative`">{{ payment.meta.refund.status }}</span>
              <small v-if="optional(payment, 'meta.refund.message')"> => <i>{{ optional(payment, 'meta.refund.message') }}</i></small>
            </div>

            <div v-if="optional(payment, 'meta.transfer')">
              <label class="text-gray-700" for="title"
                ><b>Transfer Status : </b></label
              >
              <span :class="`bg-${payment.meta.transfer.status === 'pending' ? 'green' : 'red'}-100 border border-${payment.meta.transfer.status === 'pending' ? 'green' : 'red'}-400 text-${payment.meta.transfer.status === 'pending' ? 'green' : 'red'}-700 px-4 py-3 rounded relative`">{{ payment.meta.transfer.statuss }}</span>
              <small v-if="optional(payment, 'meta.transfer.message')">: <i>
                {{ optional(payment, 'meta.transfer.message') }}</i></small>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b
                  >Amount:
                </b></label
              >
              <span class=""
                >{{ payment.currency | optional("symbol", "") }}
                {{ parseFloat(payment.amount).toFixed(2) | numberWithCommas }} (${{ payment.usd_amount | numberWithCommas }})
              </span>
            </div>
            <div>
              <label class="text-gray-700" for="title"><b>Fee : </b></label>
              <span class=""
                >{{ payment.currency | optional("symbol", "") }}
                {{ payment.fee | numberWithCommas }} (${{ payment.usd_fee | numberWithCommas }})
              </span>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Date : </b></label>
              <span class="">{{
                toLocal(payment.created_at).format("lll")
              }}</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Seeded : </b></label>
              <span class="">{{
                payment.subscription?.offer?.seeded ? 'Yes' : 'No'
              }}</span>
            </div>

            <div v-if="payment.status !== 'refunded'">
                <label class="text-gray-700" for="title"><b>Refund Amount : </b></label>
                <input
                  name="amount"
                  type="number"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="form.amount"
                />
              </div>

            <div v-if="payment.status !== 'refunded'">
                <label class="text-gray-700" for="title"><b>Refund Reason : </b></label>
                <textarea
                  name="reason"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="form.reason"
                ></textarea>
              </div>
        
          </div>

          <div
            class="flex justify-end mt-4"
            
          >
            <button v-if="hasRole($auth.user(), 'admin')"
              :disabled="loading || payment.status === 'refunded'"
              @click="refund"
              :class="`px-8 py-2 mr-4 bg-gray-${
                loading ? 200 : 800
              } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                loading ? 100 : 700
              }`"
            >
              {{ loading ? "Refunding..." : "Issue Refund" }}
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { optional, getMargin, hasRole } from "@/helpers/global";
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title:() => {
     return `Payment #${window.location.href.split('/').pop()} | Payments`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      form: {
        amount: 0,
      },
    };
  },
  methods: {
    toLocal,
    optional,
    getMargin,
    hasRole,
    refund() {
      let r = confirm(
        `Are you sure you want to refund ${this.payment.subscription?.offer?.seeded ? 'SEEDED ' : ''}payment ${this.payment.id}? This will deactivate the attached subscription and mark the payment as refunded`
      );
      if (!r) return;
      this.loading = true;
      this.$store
        .dispatch("payments/refund", {
          id: this.payment.id,
          amount: this.form.amount,
          reason: this.form.reason,
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to trigger refund");
        })
        .finally(() => (this.loading = false));
    },
   
  },
  computed: {
    payment() {
      return this.$store.getters["payments/payment"];
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("payments/getSingle", {
        id: this.$route.params.id,
        include: "user,subscription",
      }),
    ]).finally(() => {
      this.loaded = true;
      Object.assign(this.form, this.payment);
      this.form.reason = optional(this.payment, 'meta.refund.reason')
    });
  },
};
</script>
