export default {
  SET_TICKETS(state, array) {
    state.tickets = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_TICKET(state, obj) {
    state.ticket = obj;
  },
  SET_MESSAGES(state, obj) {
    state.messages = obj;
  },
}
