<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Review Request ${review.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="() => {}">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">

              <div>
                <label class="text-gray-700" for="title"
                  ><b>User : </b></label
                >
                <router-link v-if="review.user" :to="{ path: `/users/${review.user.id}` }">{{
                  review.user.username}}
                  <br>
                  <span style="color: brown">{{ review.user.first_name }} {{ review.user.last_name }}</span>
                </router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reviewer : </b></label
                >
                <router-link v-if="review.reviewer" :to="{ path: `/users/${review.reviewer.id}` }">{{
                  review.reviewer.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Subscription : </b></label
                >
                <router-link v-if="review.subscription"  :to="{ path: `/subscriptions/${review.subscription.id}` }">{{
                  review.subscription.reference
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Offer : </b></label
                >
                <router-link v-if="review.offer"  :to="{ path: `/offers/${review.offer.id}` }">{{
                  review.offer.reference
                }}
                <br>
                  <span style="color: brown">{{ review.offer.title }}</span>
                </router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reference : </b></label
                >{{
                  review.reference
                }}
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Message : </b></label
                >{{
                  review.review
                }}
              </div>

          
      
              <div>
                <label class="text-gray-700" for="title"><b>Rating: </b></label>
                {{ review.rating }}
              </div>


              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(review.updated_at).format("lll")
                }}</span>
              </div>

              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { toLocal } from "@/helpers/date";

export default {
  title:() => {
     return `Review ${window.location.href.split('/').pop()} | Reviews`
  },
  data() {
    return {
      loaded: false,
      loading: false,
    };
  },
  methods: {
    toLocal,
  },
  computed: {
    review() {
      return this.$store.getters["reviews/review"];
    },
  },
  created() {
    this.$store
      .dispatch("reviews/getSingle", {
        reference: this.$route.params.reference,
      })
      .finally(() => {
        this.loaded = true;
      });
  },
};
</script>
