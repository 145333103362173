<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'block' : 'hidden'"
      @click="changeIsOpen"
      class="
        fixed
        z-20
        inset-0
        bg-black
        opacity-50
        transition-opacity
        lg:hidden
      "
    ></div>
    <!-- End Backdrop -->

    <div
      :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="
        fixed
        z-30
        inset-y-0
        left-0
        w-64
        transition
        duration-300
        transform
        bg-gray-900
        overflow-y-auto
        lg:translate-x-0 lg:static lg:inset-0
      "
    >
      <div class="flex items-center justify-center mt-8">
        <div class="flex items-center">
          <img
            src="@/assets/img/logo.png"
            width="50px"
            height="50px"
            :alt="appName"
          />
        </div>
      </div>

      <nav class="mt-5 ">
        <router-link
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[$route.name === 'Dashboard' ? activeClass : inactiveClass]"
          to="/"
        >
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 10C2 5.58172 5.58172 2 10 2V10H18C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
              fill="currentColor"
            />
            <path
              d="M12 2.25195C14.8113 2.97552 17.0245 5.18877 17.748 8.00004H12V2.25195Z"
              fill="currentColor"
            />
          </svg>

          <span class="mx-4">Dashboard</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/services' ? activeClass : inactiveClass,
          ]"
          to="/services"
        >
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 3C3.89543 3 3 3.89543 3 5V7C3 8.10457 3.89543 9 5 9H7C8.10457 9 9 8.10457 9 7V5C9 3.89543 8.10457 3 7 3H5Z"
              fill="currentColor"
            />
            <path
              d="M5 11C3.89543 11 3 11.8954 3 13V15C3 16.1046 3.89543 17 5 17H7C8.10457 17 9 16.1046 9 15V13C9 11.8954 8.10457 11 7 11H5Z"
              fill="currentColor"
            />
            <path
              d="M11 5C11 3.89543 11.8954 3 13 3H15C16.1046 3 17 3.89543 17 5V7C17 8.10457 16.1046 9 15 9H13C11.8954 9 11 8.10457 11 7V5Z"
              fill="currentColor"
            />
            <path
              d="M11 13C11 11.8954 11.8954 11 13 11H15C16.1046 11 17 11.8954 17 13V15C17 16.1046 16.1046 17 15 17H13C11.8954 17 11 16.1046 11 15V13Z"
              fill="currentColor"
            />
          </svg>

          <span class="mx-4">Services</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/subscriptions'
              ? activeClass
              : inactiveClass,
          ]"
          to="/subscriptions"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="mx-4">Subscriptions</span>
        </router-link>


        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/offers'
              ? activeClass
              : inactiveClass,
          ]"
          to="/offers"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="mx-4">Offers</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/users' ? activeClass : inactiveClass,
          ]"
          to="/users"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Users</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/payments' ? activeClass : inactiveClass,
          ]"
          to="/payments"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="mx-4">Payments</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/refund-requests' ? activeClass : inactiveClass,
          ]"
          to="/refund-requests"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Refund Requests</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/settlements' ? activeClass : inactiveClass,
          ]"
          to="/settlements"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Settlements</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/reviews' ? activeClass : inactiveClass,
          ]"
          to="/reviews"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Reviews</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/bank-accounts' ? activeClass : inactiveClass,
          ]"
          to="/bank-accounts"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Bank Accounts</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/admins' ? activeClass : inactiveClass,
          ]"
          to="/admins"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Admins</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/tasks' ? activeClass : inactiveClass,
          ]"
          to="/tasks"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="mx-4">Tasks</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/expenses' ? activeClass : inactiveClass,
          ]"
          to="/expenses"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="mx-4">Expenses</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === 'reports/forecast' ? activeClass : inactiveClass,
          ]"
          to="/reports/forecast"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Report - Forecast</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === 'reports/summary' ? activeClass : inactiveClass,
          ]"
          to="/reports/summary"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Report - Summary</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === 'reports/subscriptions' ? activeClass : inactiveClass,
          ]"
          to="/reports/subscriptions"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Report - Subscription</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/reports/payments' ? activeClass : inactiveClass,
          ]"
          to="/reports/payments"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Report - Payment</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/reports/services' ? activeClass : inactiveClass,
          ]"
          to="/reports/services"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Report - Service</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'growth')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/reports/users' ? activeClass : inactiveClass,
          ]"
          to="/reports/users"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Report - User</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/reports/expenses' ? activeClass : inactiveClass,
          ]"
          to="/reports/expenses"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Report - Expense</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin') || hasRole($auth.user(), 'support')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/disputes' ? activeClass : inactiveClass,
          ]"
          to="/disputes"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Disputes</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/coupons' ? activeClass : inactiveClass,
          ]"
          to="/coupons"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Coupons</span>
        </router-link>

        <router-link v-if="hasRole($auth.user(), 'admin')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/app_ids' ? activeClass : inactiveClass,
          ]"
          to="/app_ids"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">App IDs</span>
        </router-link>

         <router-link v-if="hasRole($auth.user(), 'admin')"
          class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
          :class="[
            $route.path === '/referrals' ? activeClass : inactiveClass,
          ]"
          to="/referrals"
        >
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
            />
          </svg>

          <span class="mx-4">Referrals</span>
        </router-link>
    
      </nav>
    </div>
  </div>
</template>

<script >
import {hasRole} from '@/helpers/global'

export default {
  data() {
    return {
      activeClass: "bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100",
      inactiveClass:
        "border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100",
    };
  },
  computed: {
    isOpen() {
      return this.$store.getters["global/is_open"];
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
    api() {
      return process.env.VUE_APP_BASE_URI;
    },
    user() {
      let u = this.$auth.user();
      u.roles_list = u.roles_list || [];
      return u;
    },
  },
  methods: {
    hasRole,
    changeIsOpen() {
      this.$store.commit("global/SET_IS_OPEN", !this.isOpen);
    },
  },
  created() {},
};
</script>
