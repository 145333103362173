import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const conversations = {
  namespaced: true,
  state: {
    conversations : [],
    conversation : {},
    meta : {},
  },
  actions,
  getters,
  mutations
}
