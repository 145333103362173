<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Message #${conversation.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Sender : </b></label
                >
                <router-link v-if="conversation.sender" :to="{ path: `/users/${conversation.sender_id}` }">{{
                  conversation.sender && conversation.sender.username
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Conversation : </b></label
                >
                <router-link v-if="conversation.conversation" :to="{ path: `/conversations/${conversation.conversation.reference}` }">{{
                  conversation.conversation && conversation.conversation.reference
                }}</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Message : </b></label
                >
                <p v-html="conversation.message"></p>
              </div>


              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(conversation.updated_at).format("lll")
                }}</span>
              </div>

              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { toLocal } from "@/helpers/date";

export default {
  title:() => {
     return `Message #${window.location.href.split('/').pop()} | Messages`
  },
  data() {
    return {
      loaded: false,
      loading: false,
    };
  },
  methods: {
    toLocal,
  
  },
  computed: {
    conversation() {
      return this.$store.getters["messages/message"];
    },
  },
  created() {
    this.$store
      .dispatch("messages/getSingle", {
        reference: this.$route.params.reference,

      })
      .finally(() => {
        this.loaded = true;
      });
  },
};
</script>
